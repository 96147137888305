<div id="appContainer">
    <app-map></app-map>
    <div id="appOverlay">

        <div id="layersPanel" class="secondary" [ngClass]="{ 'open': showLayers }">
            <div class="panel-title title">
                <div class="center-y">
                    <h2>
                        ALL LAYERS
                    </h2>
                </div>
                <div class="btn" (click)="onToggleShowLayers()">
                    <img src="../assets/icons/close-white.svg">
                </div>
            </div>
            <div class="search-layers primary">
                <app-search-layers></app-search-layers>
            </div>
            <app-layers></app-layers>
        </div>
        <div id="viewportPanel">
            <div id="toggleLayers" class="btn primary" (click)="onToggleShowLayers()">
                <img *ngIf="!showLayers" src="../assets/icons/menu.svg">
            </div>
            <app-active-layers></app-active-layers>
            <app-goto-xy *ngIf="showGotoXY" id="gotoXY" (closeGotoXY)="onCloseGotoXY()"></app-goto-xy>
            <app-gazetteer id="gazetteer"></app-gazetteer>
            <div id="overviewMap"></div>

        </div>

        <app-legend id="legend"></app-legend>
        <app-feature-infos id="featureInfos"></app-feature-infos>


        <app-controls-toolbar id="controlsToolbar" (showGotoXY)="onShowGotoXY()"></app-controls-toolbar>
        <div id="scalePositionContainer">
            <div id="scaleLine"></div>
            <div id="mousePosition"></div>
        </div>
        
        <div id="attribution-container">
            <app-map-credit id="mapCredit"></app-map-credit>
            <div id="attribution"></div>
        </div>

    </div>
    <!-- <app-map-instances></app-map-instances> -->
    <!-- <app-map-info></app-map-info> -->
</div>