<div class="controls-container">
    <div class="controls-container-header bg-menu" (click)="toggleShow()">
      <h6>
        <img *ngIf="show" src="../../assets/icons/chevron-down.svg">
        <img *ngIf="show" src="../../assets/icons/chevron-right.svg">
        <ng-content select="[header]"></ng-content>
      </h6>
    </div>
    <div [ngClass]="{'d-none': !show}" class="controls-container-body">
        <ng-content select="[body]"></ng-content>
    </div>
  </div>