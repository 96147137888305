<div class="controls-container" cdkDrag>
  <div class="controls-container-header primary" cdkDragHandle>
    Go to Longitude/Latitude
    <div class="btn">
        <img src="../../assets/icons/close-white.svg" class="close" (click)="onCloseGotoXY()">
  </div>
  </div>
  <div class="controls-container-body">
    <div>Enter coordinates in decimal degrees</div>
    <div class="goto-xy">
        <span>Long:</span>
        <input type="text" maxlength="10" (keyup.enter)="onGotoXY(x.value, y.value)" #x>
        <span>Lat:</span>
        <input type="text" maxlength="10" (keyup.enter)="onGotoXY(x.value, y.value)" #y>
        <button type="button" class="dialog-button primary" (click)="onGotoXY(x.value, y.value)">Go</button>
    </div>
    <div *ngIf="!xValid" class="error">
      {{ xErrorMessage }}
    </div>
    <div *ngIf="!yValid" class="error">
      {{ yErrorMessage }}
    </div>
  </div>
</div>
