<div class="layer tertiary">
    <div class="layer-toggle" (click)="onToggleVisibility()" [ngClass]="layer.layerName">
        <div>
            <label class="switch" [ngClass]="layer.layerName">
                <span class="slider round" [ngClass]="layer.layerName" [class.on]="layer.visible"></span>
            </label>
        </div>
        <div class="layer-name" [ngClass]="layer.layerName">
            {{layer.name}}
        </div>
    </div>
    <div>
        <span class="badge" [tooltip]="layer.metadataDescription" hideDelay="10000">
            ?
        </span>
    </div>
</div>