<div *ngIf="subLayerGroup.name === null || subLayerGroup.name == ''">
  <ul>
    <li *ngFor="let layer of subLayerGroup.layers | layerSort">
      <app-layer [layer]="layer" (visibilityChanged)="onLayerVisibilityChanged(layer, $event)"></app-layer>
    </li>
  </ul>
</div>

<div *ngIf="subLayerGroup.name !== null && subLayerGroup.name !== ''" class="sub-layer-group secondary">
  <div class="sub-layer-group-header">
    <div class="sub-layer-group-selector" (click)="toggleShow()">
        <img class="control" *ngIf="show" src="../../../assets/icons/chevron-down.svg">
        <img class="control" *ngIf="!show" src="../../../assets/icons/chevron-right.svg">
    </div>
    <div class="sub-layer-group-title" (click)="toggleShow()">
      {{ subLayerGroup.name }}
    </div>
    <div class="visible-layers" (click)="onToggleLayers()">
        <span class="badge" [tooltip]="visibleLayers + ' active. Click to toggle all layers in this sub-group'">
          {{ visibleLayers }}
        </span>
      </div>
  </div>
  
  <div [ngClass]="{'closed': !show}" class="sub-layer-group-body">
    <ul>
      <li *ngFor="let layer of subLayerGroup.layers | layerSort">
        <app-layer [layer]="layer" (visibilityChanged)="onLayerVisibilityChanged(layer, $event)"></app-layer>
      </li>
    </ul>
  </div>
</div>