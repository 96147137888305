<div class="controls-container" id="active-layers" cdkDrag cdkDragBoundary="#viewportPanel">

    <div class="controls-container-header primary">
        <div class="drag-handle" cdkDragHandle>
            <img tooltip="move panel" src="../../assets/icons/drag-white.svg">
        </div>
        <div class="active-layers-title" (click)="toggleShow()">
            <h6>
                <span>ACTIVE LAYERS</span>
            </h6>
            <img *ngIf="show" src="../../assets/icons/chevron-down.svg">
            <img *ngIf="!show" src="../../assets/icons/chevron-left.svg">
        </div>

    </div>
    <div *ngIf="show" class="controls-container-body">
        <ul *ngIf="(visibleLayers$ | async).length; else no_visible_layers" class="active-layers-list" cdkDropList
            (cdkDropListDropped)="drop($event)">
            <li class="active-layer" cdkDrag cdkDragBoundary="#active-layers"
                *ngFor="let layer of visibleLayers$ | async">
                <app-active-layer [layer]="layer"
                    (openFilter)="openFilter(layer)"></app-active-layer>
            </li>
        </ul>
        <ng-template #no_visible_layers>No active layers</ng-template>
    </div>
</div>

<ng-template #filterOverlay let-activeLayer>
    <app-filter-controls [layer]="activeLayer" (closeFilter)="closeFilter()"></app-filter-controls>
</ng-template>