<div id="gazetteerContainer">
  <ng-select #gazetteer
              (focus)="onFocus()"
              [items]="results$ | async"
              bindLabel="name"
              [placeholder]="placeholder"
              [(ngModel)]="selectedResult"
              (change)="onChange()"
              [typeahead]="searchInput$"
              [loading]="resultsLoading">
  </ng-select>
</div>
