<div class="controls-container" id="filter-settings" cdkDrag>
    <div class="controls-container-header primary" cdkDragHandle>
        <h6>
            <span>FILTER LAYER {{ layer.name }}</span>
        </h6>
        <div class="btn">
            <img src="../../assets/icons/close-white.svg" class="close" (click)="onCloseFilter()">
        </div>
    </div>
    <div class="controls-container-body">
        <app-filter-control *ngFor="let filter of layer.filters | filterSort" [filterConfig]="filter"
            [filterLookup]="filterLookup[filter.lookupCategory]" [activeFilters]="activeFilters">
        </app-filter-control>
        <div class="controls-container-footer">
            <button (click)="onFilterApplied()" class="primary dialog-button">Apply</button>
            <button (click)="onFilterCleared()" class="secondary dialog-button">Clear</button>
        </div>
    </div>
</div>