<div>
  {{ filterConfig.name }}
</div>
<div [innerHTML]="filterConfig.description"></div>
<div *ngIf="filterConfig.type==='lookup'">
  {{ filterConfig.attribute }}: {{ filterSummary }}
</div>
<div *ngIf="filterConfig.type==='lookup'" class="filter-lookup-values">
  <ul>
    <li *ngFor="let state of filterState">
      <label>
      <input type="checkbox" [id]="state.lookup.lookupId" [value]="state.lookup.code" [checked]="state.checked" (change)="onCheckChanged(state.lookup.code, $event)">
      {{ state.lookup.name }}
    </label>
    </li>
  </ul>
</div>
<div *ngIf="filterConfig.type==='text'" class="filter-text">
  <input [(ngModel)]="filterText" type="text">
</div>
