<div class="controls-container" id="map-info">
  <div class="controls-container-header primary">
      <h6>
          <span>Map Information</span>
      </h6>
      
      <div class="btn" (click)="onCloseMapInfo()">
            <img src="../../assets/icons/close-white.svg" class="close">
      </div>
  </div>
  <div class="controls-container-body">
      <div [innerHTML]="mapInstance.description"></div>
  </div>
</div>
