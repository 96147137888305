<div class="layer-group secondary">
    <div class="layer-group-header"
        [ngClass]="{'highlight': layerGroup.isExternal, 'primary': !layerGroup.isExternal }">

        <div class="layer-group-selector" (click)="onToggleShow()">
            <img class="control" *ngIf="show" src="../../../assets/icons/chevron-down.svg">
            <img class="control" *ngIf="!show" src="../../../assets/icons/chevron-right.svg">
        </div>
        <div class="layer-group-title" (click)="onToggleShow()">
            {{ layerGroup.name }}
        </div>
        <div (click)="onToggleLayers()" class="visible-layers">
            <span class="badge" [tooltip]="visibleLayers + ' active. Click to toggle all layers in this group'">
                {{ visibleLayers }}
            </span>
        </div>


    </div>
    <div [ngClass]="{'closed': !show}" class="layer-group-body">
        <ul>
            <li *ngFor="let subLayerGroup of layerGroup.subLayerGroups">
                <app-sub-layer-group [subLayerGroup]="subLayerGroup"
                    (layerVisibilityChanged)="onLayerVisibilityChanged($event)"></app-sub-layer-group>
            </li>
        </ul>
    </div>
</div>