<div id="featureInfoPane" [ngClass]="{ 'show': (featureInfos$ | async).length }">
    <div class="panel-title title">
        <div class="btn">
            <img src="../../assets/icons/close-white.svg" (click)="clearFeatureInfo()">
        </div>
        <div class="center-y">
            <h2>
                FEATURE INFO
            </h2>
        </div>
    </div>
    <div class="feature-info-body">
        <div *ngFor="let featureInfo of featureInfos$ | async; index as i">
            <h3 class="highlighted-layer" *ngIf="i === 0 && allowLayerHighlight">Selected Feature</h3>
            <div class="overflow" [class.highlighted-layer]="i === 0 && allowLayerHighlight" [innerHTML]="featureInfo"></div>
            <hr>
        </div>
    </div>
</div>