<div id="legendPane" [ngClass]="{ 'show': show }" class="secondary">
    <div *ngIf="legend">
        <div class="panel-title title">
            <div class="btn">
                <img src="../../assets/icons/close-white.svg" (click)="onHideLegend()">
            </div>
            <div class="center-y">
                <h2>
                    LEGEND
                </h2>
            </div>

        </div>
        <div class="panel-title primary">
            <h2>{{ legend.name }}</h2>
        </div>
        <div class="legend-body">
            <img *ngIf="loading" src="../../assets/icons/loading.gif" style="width: 200px;">
            <img #legendImage [hidden]="loading" [src]="legend.legendUrl" (load)="onLegendImageLoaded()" alt="legend">
        </div>
    </div>
</div>