<div class="layers">
 
  <ul>
    <li *ngFor="let layerGroup of layerGroups$ | async">
      <app-layer-group [layerGroup]="layerGroup" (layerVisibilityChanged)="onLayerVisiblityChanged($event)">
      </app-layer-group>
    </li>
  </ul>
  <div class="external-layers primary">
    <div class="add-external-layers" (click)="onAddExternalLayers()">
      <div class="center-y">
        Add external layers
      </div>
      <div class="btn">
          <img src="../../assets/icons/plus-white.svg" class="control" tooltip="add external layers">
      </div>
    </div>
  </div>
</div>

<ng-template #externalLayersOverlay let-externalWmsUrls>
  <app-external-layers [externalWmsUrls]="externalWmsUrls" (closeExternalLayers)="closeExternalLayers()"></app-external-layers>
</ng-template>