<div class="controls-toolbar">
    <img class="control-icon" tooltip="base maps" src="../../assets/icons/map-outline.svg" (click)="onShowBaseLayers($event)">
    <img class="control-icon" tooltip="share map link" src="../../assets/icons/share-variant.svg" (click)="onShowPermalink()">
    <img class="control-icon" tooltip="map information" src="../../assets/icons/information-toolbar.svg" (click)="onShowMapInfo()">
    <div class="divider"></div>
    <img class="control-icon" tooltip="zoom in" src="../../assets/icons/plus.svg" (click)="onZoomIn()">
    <img class="control-icon" tooltip="zoom out" src="../../assets/icons/minus.svg" (click)="onZoomOut()">
    <img class="control-icon" tooltip="zoom to map extent" src="../../assets/icons/earth-toolbar.svg" (click)="onZoomToMapExtent()">
    <img class="control-icon" tooltip="drag zoom in" src="../../assets/icons/magnify-plus-outline.svg" (click)="onZoomInToExtent()">
    <img class="control-icon" tooltip="drag zoom out" src="../../assets/icons/magnify-minus-outline.svg" (click)="onZoomOutToExtent()">
    <div class="divider"></div>
    <img class="control-icon" tooltip="go to long/lat" src="../../assets/icons/crosshairs.svg" (click)="onShowGotoXY()">
    <img class="control-icon" tooltip="measure" src="../../assets/icons/ruler.svg" (click)="onShowMeasure()">
</div>

<ng-template #baseLayersOverlay>
    <div class="controls-container">
        <div class="controls-container-header primary">
            <h6>
                BASE MAPPING
            </h6>
            <div (click)="closeBaseLayers()">
                <img src="../../assets/icons/close-white.svg" class="close">
            </div>
        </div>
        <div class="controls-container-body">
            <app-base-layers></app-base-layers>
        </div>
    </div>
</ng-template>

<ng-template #measureOverlay>
    <div class="controls-container">
        <div class="controls-container-header primary">
            <h6>
                Measure
            </h6>
            <div (click)="closeMeasure()">
                <img src="../../assets/icons/close-white.svg" class="close">
            </div>
        </div>
        <div class="controls-container-body">
            <app-measure></app-measure>
        </div>
    </div>
</ng-template>

<ng-template #mapInfoOverlay>
    <app-map-info (closeMapInfo)="closeMapInfo()"></app-map-info>
</ng-template>

<ng-template #permalinkOverlay let-permalink>
    <app-permalink [permalink]="permalink" (closePermalink)="closePermalink()"></app-permalink>
</ng-template>
