<div class="controls-container" id="permalink">
  <div class="controls-container-header primary">
      <h6>
          <span>Share map link</span>
      </h6>
      <div class="btn" (click)="onClosePermalink()">
          <img src="../../assets/icons/close-white.svg" class="close">
      </div>
  </div>
  <div class="controls-container-body">
    <textarea id="permalinkTextArea" readonly [value]="permalink" #inputElement></textarea>
    <button class="primary dialog-button" (click)="onCopyPermalink(inputElement)"> {{ copyText }} </button>
  </div>
</div>
