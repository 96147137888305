<app-controls-container id="mapInstances">
  <span header>MAP INSTANCES</span>
  <ng-container body>
    <ul>
      <li *ngFor="let mapInstance of mapInstances">
        <h6>{{mapInstance.name}}</h6>
        <div [innerHTML]="mapInstance.description"></div>
      </li>
    </ul>
  </ng-container>
</app-controls-container>
